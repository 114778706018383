<template lang="pug">
router-link.d-block.q-ml-lg.cursor-pointer(
    v-if="link"
    :to="link"
    :title="`${title}`"
)
    .column.justify-center.items-center.icon-block.relative-position
        q-icon(
            v-if="staticIcon"
            :name="`etabl:${icon}`"
            :color="colorIcon"
            :size="size")
        q-icon(
            v-else
            :name="`img:${icon}`"
            :size="size")
        .font-12.text-primary.text-weight-medium {{ title }}
        q-badge(
            v-if="count"
            rounded
            floating
            color="negative"
            :style="`top: ${badgePosition[0]}px; right: ${badgePosition[1]}px;`")
            span.full-width.text-weight-bold.roboto.font-10 {{ count }}
    slot(name="default")
.d-block.q-ml-lg.cursor-pointer(v-else)
    .column.justify-center.items-center.icon-block.relative-position
        q-icon(
            v-if="staticIcon"
            :name="`etabl:${icon}`"
            :color="colorIcon"
            :size="size")
        q-icon(
            v-else
            :name="`img:${icon}`"
            :size="size")
        .font-12.text-primary.text-weight-medium {{ title }}
        q-badge(
            v-if="count"
            rounded
            floating
            color="negative"
            :style="`top: ${badgePosition[0]}px; right: ${badgePosition[1]}px;`")
            span.full-width.text-weight-bold.roboto.font-10 {{ count }}
    slot(name="default")
</template>

<script>
export default {
    name: 'IconBlock',
    props: {
        link: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: '20px',
        },
        icon: {
            type: String,
            required: true,
        },
        colorIcon: {
            type: String,
            default: () => 'positive',
        },
        title: {
            type: String,
            required: true,
        },
        count: {
            type: Number,
            default: () => Number(0),
        },
        badgePosition: {
            type: Array,
            default: () => [-4, 0],
        },
        staticIcon: {
            type: Boolean,
            default: () => true,
        },
    },
    setup(props, { emit }) {
        const mouseOver = () => {
            emit('mouseover');
        };

        const mouseLeave = () => {
            emit('mouseleave');
        };

        return {
            mouseOver,
            mouseLeave,
        };
    },
};
</script>

<style lang="scss" scoped>
.d-block {
    @media screen and (max-width: 1280px) {
        margin-left: 16px;
    }
}
</style>
