<template lang="pug">
.wrapper.row.justify-between.items-center.relative-position
    router-link.link.font-16.line-height-22.text-weight-medium.text-positive.q-px-lg.q-py-sm.marketing(
        @click="sendMetric"
        :to="city ? `/${city.slug}/marketing-list` : '/marketing-list'"
        title="Акции")
        | Акции
    template(v-for="item in links")
        router-link.link.row.q-px-md.q-py-sm.text-primary.fast-link(
            v-if="item.condition"
            :to="city ? `/${city.slug}${item.link}` : item.link"
            :title="`${item.title}`")
            q-icon(
                :name="`etabl:${item.icon}`"
                color="primary"
                size="sm"
            ).q-mr-xs
            .font-16.q-ml-sm.text-weight-medium
                | {{ item.title }}
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'FastLinks',
    setup() {
        const store = useStore();
        const route = useRoute();
        const q = useQuasar();

        const windowWidth = ref(0);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const links =computed(()=>[
            {
                title: 'Витамины и микроэлементы',
                link: '/catalog/vitaminy-i-mikroelementy=000000016',
                icon: 'vitamins',
                condition: true,
            },
            {
                title: 'Простуда и грипп',
                link: '/catalog/prostuda-i-gripp=000000169',
                icon: 'virus',
                condition: true,
            },
            {
                title: 'Красота и уход',
                link: '/catalog/krasota-i-uхod=000000199',
                icon: 'beauty',
                condition: true,
            },
            {
                title: 'Медицинские приборы',
                link: '/catalog/pribory-medicinskie=000000346',
                icon: 'devices',
                condition: windowWidth.value >= 1280,
            },
            {
                title: 'Оптика',
                link: '/catalog/optika=000000569',
                icon: 'optics',
                condition: windowWidth.value >= 1280,
            },
        ]); 
        
        const sendMetric = ()=>{
            ym(72234883,'reachGoal','banner_page_visit');
        };
        const updateWidth = () => {
            windowWidth.value = window.innerWidth;
        };
        onMounted(()=>{
            updateWidth();
            window.addEventListener('resize', updateWidth);
        });

        return {
            links,
            city,
            windowWidth,
            sendMetric,
        };
    },
};
</script>

<style lang="scss" scoped>

.fast-link {
    &:hover {
        color: $positive !important;
    }

    border-radius: 26px; 
    background: $grey-20;
}

.marketing {
    background: #E1F2E9;
    border-radius: 26px;
}
</style>
